/***** STUDENTS PAGES STYLES *****/
.students-page .card {
  box-shadow: none;
  border: none;
  padding: 24px 24px 90px 24px;
}
@media only screen and (max-width: 560px) {
  .students-page.page.container {
    padding: 0;
  }
  .students-page .card {
    padding: 16px 16px 90px 16px;
  }
}
.student-page-tabs {
  border-bottom: 1px solid #d8d8dd;
  margin-bottom: 24px;
}

.student-details-page.page.container {
  padding: 24px 24px 64px 24px;
  max-width: 1200px;
}

/*** Student Buttons list Styles ***/
.students-buttons-list {
  display: flex;
  align-items: center;
}
.students-buttons-container {
  display: contents;
}
@media only screen and (max-width: 560px) {
  .students-buttons-list {
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;
  }
  .students-buttons-container {
    display: flex;
  }
}
/*** Student Filters Styles ***/
.students-filters .search-input {
  width: 400px;
}
.student-list-filter {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.student-list-filter-select {
  @extend .filter-select;
  min-width: 120px;
}

.student-list-filter-select > .student-list-filter-select__control {
  border: none;
  background: transparent;
  cursor: pointer;
}

.student-list-filter-select__single-value {
  color: $accent-color !important;
  position: static !important;
  transform: none !important;
  max-width: none !important;
}

.student-list-filter-select__indicator-separator {
  display: none;
}

.student-list-filter-select__dropdown-indicator {
  color: $accent-color !important;
}

.student-list-filter-select__placeholder {
  color: $accent-color !important;
  position: static !important;
  transform: none !important;
  max-width: none !important;
}
.student-list-filter-select__indicator {
  margin-left: -14px;
}
.student-list-filter-select__menu {
  min-width: 180px;
}

@media only screen and (max-width: 1200px) {
  .students-filters-container {
    display: block;
  }
  .students-filters {
    display: block;
  }
  .students-filters .search-input {
    width: 680px;
    max-width: 100%;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 560px) {
  .students-filters .search-input {
    width: 100%;
  }
  .student-list-filter {
    margin-left: 0px;
    width: 100%;
  }
}
/*** Students List Styles ***/
.students-list-table-row-container {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 0 16px;
  background: #fff;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0px;
}
.students-list-table-row {
  background: #fff;
  grid-gap: 0 16px;
  margin-bottom: 0px;
  padding: 12px 16px;
  border-radius: 0;
  border-bottom: 0px;
}
@media only screen and (max-width: 560px) {
  .paginated-table-header-row.students-list-table-row {
    display: none;
  }
  .students-list-table-row {
    display: block;
    padding: 8px 16px;
    border-top: 1px solid #edecec;
    border-radius: 0;
    margin: 0 -16px;
    position: relative;
  }
}

.my-students-table .student-group-table-row {
  padding: 2px 2px;
  grid-template-columns: 40px 1.5fr 1fr 4fr 0.5fr 0.3fr;
  grid-gap: 0 16px;
  @media only screen and (max-width: 560px) {
    grid-template-columns: 25px 1fr 30px;
    padding: 8px 10px;
    border-top: 1px solid #edecec;
    border-radius: 0;
    margin: 0 -16px;
    position: relative;
  }
}
.student-group-table-header {
  @media only screen and (max-width: 560px) {
    display: none;
  }
}
.students-groups-table-controls {
  padding: 2px 2px;
  border: unset;
  display: flex;
  border: 1px solid #d8d8dd;
  justify-content: space-between;
  @media only screen and (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .student-groups-heading-icons {
    font-size: 18px;
    margin-right: 5px;
  }
}
.student-tag-chip {
  margin-right: 5px;
  margin-top: 5px;
}

/*** Student Import Modal Styles ***/
.students-import-modal-btns {
  @extend .raffle-modal-btns;
}

/*** Student Details Engagement Styles ***/
/* Student Interaction Chart */
.student-interactions-chart .recharts-cartesian-axis-ticks {
  font-size: 12px;
}
@media only screen and (max-width: 560px) {
  .student-interactions-chart .recharts-surface {
    padding-right: 12px;
  }
}
.engagement-interactions-checkbox-container {
  display: flex;
  margin-bottom: 0;
}
.engagement-interactions-checkbox-container .radio-button-container {
  margin-bottom: 0;
}
.engagement-interactions-checkbox-container label {
  display: flex !important;
}
/* Student Trajectory Charts */
.shabbos-observance-chart .recharts-layer .recharts-cartesian-axis-tick {
  font-size: 10px;
}

/*** Student Goals and Metrics ***/
.student-engagement-page .divider {
  background-color: #e0e0e0;
  width: 1px;
  margin: -32px 32px;
}

.student-engagement-page .full-horizontal-divider {
  background-color: #e0e0e0;
  height: 1px;
  margin: 32px -32px;
}

.student-engagement-page .partial-horizontal-divider {
  background-color: #e0e0e0;
  height: 1px;
}

/***** STUDENT DETAILS STYLES *****/
@media only screen and (max-width: 560px) {
  .student-details-card {
    box-shadow: none;
    border-radius: 0;
    margin: 0 -16px;
    width: calc(100% + 32px);
    padding: 16px;
  }
}
/*** Top Card Styles ***/
.student-details-name {
  font-size: 36px;
  font-family: AvenirBlack;
}
.student-details-name img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 8px;
}

@media only screen and (max-width: 560px) {
  .student-details-name {
    font-size: 20px;
  }
}
/*** Sidebar Menu Styles ***/
.student-details-sidebar-menu-card {
  height: fit-content;
  min-width: 256px;
}
.student-details-sidebar-menu-card .link-text-secondary {
  text-transform: uppercase;
  margin-left: -32px;
  padding-left: 16px;
  margin-bottom: 32px;
}
.student-details-sidebar-menu-card .link-text-secondary:last-child {
  margin-bottom: 0;
}
.student-details-sidebar-menu-card .link-text-secondary.active {
  border-left: 4px solid;
  color: #2774ae;
  padding-left: 12px;
}
.student-details-modal .student-details-sidebar-menu-card {
  min-width: 160px;
}
@media only screen and (max-width: 1040px) {
  .card.student-details-sidebar-menu-card {
    margin: 0;
    padding: 0 16px;
    background: #fafafa;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .student-details-sidebar-menu-card .link-text-secondary {
    border-bottom: 2px solid transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    display: inline-block;
    line-height: 40px;
  }
  .student-details-sidebar-menu-card .link-text-secondary.active {
    border-bottom: 2px solid #2774ae;
    color: #2774ae;
    opacity: 1;
    border-left: none;
    padding-left: 0;
  }
}
@media only screen and (max-width: 560px) {
  .card.student-details-sidebar-menu-card {
    margin: 0;
    padding: 0;
    overflow: auto;
    width: 100%;
  }
  .student-details-modal .student-details-sidebar-menu-card.card {
    margin: 0 -32px 24px;
    padding: 0 16px;
    width: calc(100% + 64px);
  }
  .student-details-sidebar-menu-card .link-text-secondary {
    white-space: nowrap;
  }
}

/*** Content Card Styles ***/
/* Tab Styles */
.student-details-tabs {
  display: flex;
  border-bottom: 1px solid #edecec;
  margin-bottom: 40px;
}
.student-details-tabs .link-text-secondary {
  margin-right: 48px;
  border-bottom: 3px solid transparent;
  font-size: 18px;
  padding-bottom: 4px;
}
.student-details-tabs .link-text-secondary.active {
  color: $accent-color;
  border-bottom: 3px solid $accent-color;
}
@media only screen and (max-width: 560px) {
  .student-details-tabs {
    display: none;
  }
}
/* Student Details Profile Background Styles */
.student-details .two-column-grid {
  grid-gap: 8px;
}
.student-background-divider {
  margin: 24px 0;
  background-color: #edecec;
  height: 1px;
}
.student-background .two-column-grid {
  grid-gap: 16px;
}
.student-background .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
@media only screen and (max-width: 560px) {
  .student-background {
    font-size: 12px;
  }
  .student-background .medium-text {
    font-size: 12px;
  }
  .student-background .two-column-grid {
    grid-template-columns: 1fr;
  }
}
/* Student Details Profile Other Styles */
.student-profile-tag {
  background-color: #2774ae;
  color: #fff;
  height: 30px;
  border-radius: 19.5px;
  font-family: AvenirRoman;
  padding: 0 16px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}
.trips-students-table {
  .paginated-table-row:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .paginated-table-row.students-table-row {
    margin-bottom: 0px;
  }
}
/* Student Details Courses/Rewards Styles */
.student-courses-table-row {
  @extend .paginated-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 12px 16px;
}
.student-courses-table-header-row {
  @extend .paginated-table-header-row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 12px 16px;
  grid-gap: 16px;
}
.student-rewards-table-row {
  @extend .student-courses-table-row;
}
.student-rewards-table-header-row {
  @extend .student-courses-table-header-row;
}
.student-details .empty-state {
  border-bottom: 1px solid #edecec;
}
@media only screen and (max-width: 560px) {
  .student-details-tabs .medium-text.mobile-block {
    font-size: 14px;
  }
  .student-courses-table-row {
    display: block;
    background: #fff;
    margin: 0 -16px;
    border-top: 1px solid #edecec;
    font-size: 14px;
  }
  .student-courses-table-row:last-child {
    border-bottom: 1px solid #edecec;
    margin-bottom: 40px;
  }
  .student-courses-table-header-row {
    display: none;
  }
}
/*** Student Details Modal Styles ***/
.student-details-modal {
  width: 1000px;
  max-width: 98%;
}
.student-details-modal .card {
  box-shadow: none;
}
@media only screen and (max-width: 560px) {
  .student-details-modal {
    padding: 24px 32px;
    overflow-x: hidden;
  }
  .student-details-modal .card {
    padding: 0;
    border: none;
  }
  .student-details-modal .full-width.ml-32 .medium-text {
    font-size: 14px;
  }
}

/*** Student Activity List Styles ***/
.students-activity-table .students-activity-table-row {
  @extend .students-table-row;
}
.students-activity-table .students-activity-table-row {
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d8d8dd;
  border-radius: 2px;
}
@media only screen and (max-width: 560px) {
  .students-activity-table .students-activity-table-row {
    margin-bottom: 0;
    padding: 16px;
    display: block;
    box-shadow: none;
  }
}

/*** Student Interaction Modal Styles ***/
.student-interaction-modal label {
  width: 100px;
  min-width: 100px;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
  margin-right: 8px;
}
.student-interaction-modal .radio-container input + label {
  margin-bottom: 0;
}
.student-interaction-modal .custom-select,
.student-interaction-modal .custom-input,
.student-interaction-modal .react-datepicker-wrapper {
  // max-width: 240px;
  width: 100%;
}
.student-interaction-modal .two-column-grid .radio-container input + label {
  width: 50%;
}
.student-interaction-modal .custom-input,
.student-interaction-modal .custom-checkbox-container label {
  width: 100%;
}
.student-interaction-modal
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.student-interaction-modal .custom-select.interaction-type-select {
  .material-icons {
    font-family: "Material Icons";
  }
  .custom-select__single-value .interaction-category-details {
    display: none;
  }
}
.student-interaction-modal
  .custom-select.interaction-type-select
  .custom-select__option--is-selected {
  .accent-text {
    color: #fff;
  }
}
.student-interaction-modal
  .custom-select.interaction-type-select
  .custom-select__value-container.custom-select__value-container--has-value
  .interaction-category-name {
  display: none;
}
.interaction-category-rating {
  width: 150px;
  min-width: 150px;
  img {
    height: 14px;
  }
}
.interaction-duplicate-modal {
  width: 600px;
}
.student-interaction-modal .interaction-type-description {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 96px;
}
.students-section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $accent-text-light;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.student-card {
  border: 1px solid $accent-text-light;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 16px;
  margin-bottom: 8px;
  overflow: visible;
  > div:first-child {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px 16px;
    width: 100%;
    .custom-checkbox-container {
      grid-column-start: span 2;
    }
  }
  .custom-checkbox-container {
    width: max-content;
  }
  label.accent-text-dark {
    width: 180px;
    min-width: 180px;
  }
  .student-friends-toggle label {
    min-width: 32px !important;
  }
  .custom-select__menu-list {
    max-height: 250px;
  }
}
.student-interaction-modal-btns {
  @extend .raffle-modal-btns;
}
.student-interaction-modal-btns .dropdown {
  left: auto;
  right: 0;
  bottom: 32px;
}
.interaction-category-details {
  white-space: nowrap;
}
.student-interaction-modal .multiselect__control--is-focused {
  border: none !important;
}
@media only screen and (max-width: 840px) {
  .student-card {
    display: block;
    overflow: auto;
    padding-bottom: 16px;
  }
  .student-card > div:first-child {
    grid-template-columns: 1fr;
    .custom-checkbox-container {
      grid-column-start: auto;
    }
  }
  .student-card i {
    float: right;
  }
}
@media only screen and (max-width: 560px) {
  .student-interaction-modal {
    height: 100vh;
    width: 100vw;
  }
  .student-interaction-modal.card {
    padding: 24px;
  }
  .student-interaction-modal .react-datepicker-wrapper {
    display: block;
  }
  .student-interaction-modal .interaction-type-description {
    display: block;
    margin-left: 0;
  }
  .student-interaction-modal-btns {
    flex-wrap: wrap;
    margin: 0px 16px 60px 0px;
  }
  .student-interaction-modal-btns > button:first-of-type {
    width: 100%;
    margin-bottom: 8px;
  }
  .student-interaction-modal-btns > div {
    width: 100%;
    margin-right: 0;
  }
  .student-interaction-modal .grant-requirements-tooltip {
    width: 200px;
    right: auto;
    left: -48px;
  }
  .student-interaction-modal label {
    width: 124px;
  }
}

/***** PROFILE FORM STYLES *****/
.profile-form .profile-form-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 320px));
  grid-gap: 16px 40px;
}
.profile-form .profile-form-grid.parent-contact-info-grid {
  grid-template-columns: repeat(2, minmax(auto, 320px));
}
.profile-form .profile-form-section {
  margin-bottom: 40px;
}
.profile-form .profile-section-title {
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 16px;
}
.profile-form .input-container {
  height: 48px;
  position: relative;
}
.profile-form .input-container .error-message {
  position: absolute;
  bottom: -15px;
  left: 16px;
  font-size: 12px;
}
.profile-form .required-text {
  color: $error-color;
}
.profile-form .input-container-select__control {
  border: none;
  background: transparent;
  position: relative;
  top: -8px;
  font-size: 12px;
  left: -8px;
  width: calc(100% + 16px);
}
.profile-form .input-container-select__control--is-focused {
  border: none;
  outline: none !important;
  box-shadow: none;
}
.profile-form .input-container-select__indicator-separator {
  display: none;
}
.profile-form .phone-input {
  height: 18px;
  border: none;
  margin-top: -2px;
}

.profile-form .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.profile-form .input-container .react-datepicker-popper {
  z-index: 2;
}
.school-error-message {
  font-size: 12px;
}
.profile-school-loader svg#loading {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 16px;
}
.profile-form .btn {
  width: 120px;
}
.profile-form .toggle-container {
  padding: 0;
}
.toggle-label {
  @extend .uppercase-text;
  @extend .xs-text;
  @extend .accent-text;
  @extend .mb-4;
  @extend .block;
}
.profile-form textarea.custom-input {
  height: 60px;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-family: AvenirMedium;
  resize: none;
}
.profile-form-section .chip-container {
  min-height: auto;
}
.save-btn-container .error-message {
  white-space: nowrap;
  right: 0;
  bottom: -16px;
}
.success-text {
  position: absolute;
  white-space: nowrap;
  right: 0;
  top: 52px;
  font-size: 13px;
  color: #2d7c3b;
}
@media only screen and (max-width: 1040px) {
  .profile-form .profile-form-grid,
  .profile-form .profile-form-grid.parent-contact-info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 840px) {
  .profile-form .input-container.jewishIsraelKnowledgeLevel-select {
    height: 60px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form .input-container {
    height: auto;
  }
  .profile-form .profile-form-grid,
  .profile-form .profile-form-grid.parent-contact-info-grid {
    grid-template-columns: 1fr;
  }
  .profile-form .profile-form-grid.allergy-info-grid {
    display: block;
  }
  .profile-form .profile-form-grid.allergy-info-grid .input-container {
    margin-top: 16px;
  }
  .school-error-message {
    margin-top: 2px;
    font-size: 10px;
  }
  .profile-form .btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .success-text {
    width: 100%;
    text-align: center;
  }
}

/***** MODAL PROFILE FORM STYLES *****/
.modal .profile-form .save-btn-container {
  position: unset;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  grid-column-start: span 2;
}
@media only screen and (max-width: 560px) {
  .modal .profile-form .save-btn-container {
    grid-column-start: 1;
  }
}
.student-profile-modal-btn {
  margin: 8px;
}

.student-profile-modal-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.student-profile-modal-tabs li {
  min-width: 180px;
  text-transform: uppercase;
  padding-bottom: 16px;
  border-bottom: 1px solid $accent-text-light;
  transition: all 300ms linear;
  text-align: center;
}
.student-profile-modal-tabs li:hover {
  color: $accent-color;
  cursor: pointer;
}
.student-profile-modal-tabs li.active {
  padding-bottom: 15px;
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
  cursor: pointer;
}
.student-profile-modal-tabs + .profile-form {
  max-height: calc(96vh - 216px);
  overflow: auto;
  margin-right: -32px;
  padding-right: 32px;
}
.new-student-profile .student-profile-modal-tabs + .profile-form {
  max-height: calc(96vh - 260px);
}

.rejected-chip {
  background-color: #ff6b6b !important;
  margin-left: 8px;
}
/***** STUDENT ACTIVITY PAGE STYLES *****/
.activity-page-title-container {
  @extend .page-title;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 560px) {
  .activity-page-title-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .activity-page-title-container h4 {
    margin-bottom: 16px;
  }
}
.link {
  color: "#747579";
  font-family: "AvenirRoman";
  font-size: 16;
  letter-spacing: 0;
  line-height: "22px";
  display: "inline";
}

.target-student-interaction-select .multiselect > div {
  position: relative;
}
.target-student-interaction-select .custom-select > div:first-of-type {
  height: 100%;
}
.custom-checkbox-container.disabled label {
  cursor: not-allowed;
  opacity: 0.5;
}
