/***** RSVP PAGE STYLES *****/
@media only screen and (max-width: 560px) {
  .page.rsvp-page {
    padding: 16px 16px 90px 16px;
  }

  .rsvp-list-container .program-form-section {
    padding: 0px;
  }
}

.rsvp-page {
  padding: 24px 24px 40px 24px;
  background: #fff;
}

.rsvp-list-container {
  @extend .full-width;

  @media only screen and (max-width: 560px) {
    padding: 0px;
  }
}

.rsvp-list-container .program-form-section:not(:first-of-type) {
  border: 0px;
}

.rsvp-card {
  padding: 15px;
  font-weight: bold;
}

// sms mailbox styles
.sms-message-details-popup {
  width: 300px;
  margin: 15px;

  .labels-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    font-size: 12px;
  }

  .recipient-person-name {
    margin-bottom: 2px;
  }
}

.sms-mailbox-page {
  @extend .flat-card;
  height: calc(100vh - 72px);
  background-color: #fff;
  width: 100%;

  @media only screen and (max-width: 560px) {
    height: calc(100vh - 56px);
  }

  .sms-mailbox-page-content {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  .mailbox-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    padding: 24px;
  }

  .mailbox-header-title {
    color: #1e1c1c;
    font-family: Archivo;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 26px;
    text-transform: capitalize;
  }

  .mailbox-list {
    padding-top: 0px;
    height: 100%;
  }

  .mailbox-status-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
    img {
      max-height: 200px;
    }
  }

  .sms-thread-container {
    @media screen and (max-width: 1040px) {
      width: 100%;
      overflow-y: auto;
    }

    .sms-thread-inner-container {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 72px);
      justify-content: space-between;
      padding: 40px 44px 0;

      .sms-thread-content-container {
        height: 100%;
        overflow-x: auto;
        padding: 0px 24px;

        .scheduled-active-message {
          border-top: 1px solid #d8d8dd;
          margin: 65px 15px 15px;
          padding-top: 35px;

          .scheduled-info-footer {
            display: flex;
            justify-content: space-between;
          }

          .scheduled-info {
            color: #1e1c1c;
            font-family: Avenir;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            display: flex;
            align-items: center;
          }

          .scheduled-icon {
            color: #ff9e57;
            margin-right: 10px;
          }
        }

        .sms-message {
          border-top: 1px solid rgba(0, 0, 0, 0.12);
          padding-bottom: 16px;
          word-wrap: break-word;
        }

        .sms-top-message {
          @extend .sms-message;
          border-top: 0px;
        }

        .sms-message-top-row {
          display: flex;
          justify-content: space-between;
          margin: 15px;
        }
      }
    }

    .message-thread-title-line {
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      padding-top: 15px;
      margin-right: 15px;
    }

    .message-to {
      margin-left: 15px;
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    .active-message {
      animation: active-background 2s 1;
      -webkit-animation: active-background 2s 1;
    }

    @keyframes active-background {
      0% {
        background: #fff;
      }

      10% {
        background: aliceblue;
      }

      100% {
        background: #fff;
      }
    }

    @-webkit-keyframes active-background

    /* Safari and Chrome */ {
      0% {
        background: #fff;
      }

      10% {
        background: aliceblue;
      }

      100% {
        background: #fff;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding: 24px 40px;

      .reply-button {
        margin-left: 16px;
      }
    }
  }

  .sms-mailbox-toggle {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .sms-mailbox-toggle-active {
    @extend .sms-mailbox-toggle;
    background: #2774ae !important;
    color: #fff !important;
  }

  .sms-mailbox-list-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rsvp-page-link {
  padding-left: 8px;
  margin-bottom: 24px;
  padding-right: 8px;
  text-transform: uppercase;
}

.rsvp-page-link-active {
  @extend .rsvp-page-link;
  padding-left: 22px;
  border-left: 2px solid #2774ae;
  position: relative;
  left: -16px;
  color: #2774ae;
}

/***** RSVP ENROLLMENT FORM STYLES *****/
.rsvp-enrollment-settings-card
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}

.rsvp-enrollment-settings-card .program-form-section {
  max-width: 1040px;
  border-top: none;
  padding-top: 0px;
}

@media only screen and (max-width: 560px) {
  .rsvp-enrollment-settings-title {
    padding-top: 0;
  }

  .rsvp-enrollment-settings-card {
    padding: 0;
    border: none;

    .program-form-section {
      max-width: 100%;
    }
  }
}

/*** Custom Questions Styles ***/
.custom-question-answer {
  max-width: 320px;
}

.custom-question-checkbox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.custom-questions-radio-title {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.custom-question-question {
  color: rgba(0, 0, 0, 0.87);
}

.custom-question-checkbox .custom-checkbox[type="checkbox"] + label {
  align-items: center;
}

@media only screen and (max-width: 560px) {
  .custom-question-answer {
    max-width: unset;
  }
}

/*** Event Permissions Styles ***/
.rsvp-private-access-note {
  margin-top: 16px;
  background-color: #e8edf1;
  border-radius: 4px;
  padding: 14px;
  display: flex;

  i {
    color: #0d324e;
    width: 18px;
    margin-right: 14px;
  }
}

/***** RSVP EVENTS LIST STYLES *****/
/*** Header Styles ***/
.rsvp-events-list-header-actions > *:not(:first-child) {
  margin-left: 16px;
}

@media only screen and (max-width: 560px) {
  .rsvp-events-list-header-actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
  }

  .rsvp-events-list-header-actions > * {
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }

  .rsvp-events-list-header-actions > *:not(:first-child) {
    margin-left: unset;
  }
}

/*** List Tab Styles ***/
/* .rsvp-list-tabs {position: relative; top: 64px; margin-bottom: 24px;}
.rsvp-list-tab {border-bottom: 2px solid transparent; max-width: 100%; width: 280px; padding-bottom: 8px}
.rsvp-list-tab.active {color: $accent-color; border-bottom: 2px solid $accent-color;}
@media only screen and (max-width: 1040px){
  .rsvp-list-tabs {top: 48px;}
} */
.rsvp-list-tabs {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
}

/* @media only screen and (max-width: 560px){
  .rsvp-list-tabs {position: unset; margin-top: 48px;} */
.rsvp-list-tab {
  border-bottom: 2px solid transparent;
  max-width: 100%;
  width: 280px;
  padding-bottom: 8px;
}

.rsvp-list-tab.active {
  color: $accent-color;
  border-bottom: 2px solid $accent-color;
}

@media only screen and (max-width: 560px) {
  .rsvp-list-tabs {
    position: unset;
    margin-top: 48px;
  }
}

/*** List/Grid Styles ***/
.rsvp-events-list {
  @extend .program-list;
}

.rsvp-events-cards-grid > div {
  @extend .program-cards-grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 315px));
}

.rsvp-resources-cards-grid > div {
  display: grid;
  row-gap: 16px;
  width: 100%;
}

.events-list-pagination {
  @extend .program-list-pagination;
}

.rsvp-events-list-card {
  padding: 0px;
}

/*** Card Styles ***/
.rsvp-card {
  @extend .program-card;
  padding-top: 24px;
  height: auto;
}

.rsvp-resource-card {
  display: flex;
  flex-direction: row;
  height: 104px;
  padding: 16px;
  justify-content: space-between;
  border: 1px solid #d8d8dd;
  border-radius: 2px;
}

@media only screen and (max-width: 560px) {
  .rsvp-resource-card {
    flex-direction: column;
    height: 158px;
  }
}

.rsvp-sidebar {
  height: calc(100vh - 72px);
  border-right: 1px solid lightgray;
}

.rsvp-side-top-menu-card {
  @extend .rsvp-card;
  padding-bottom: 0px !important;
}

.rsvp-card img {
  height: auto;
  padding-bottom: 0;
  margin-bottom: 32px;
}

.rsvp-resource-card img {
  height: 72px;
  width: 72px;
  margin-right: 32px;
  object-fit: cover;
}

.rsvp-card-footer {
  @extend .program-card-footer;
}

.resource-status-tag {
  font-size: 8px;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 3px;
}

.resource-status-tag.Available {
  color: #4fcec5;
  background-color: #effffe;
}

.resource-status-tag.Unavailable {
  color: #747579;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 1040px) {
  .card.rsvp-side-top-menu-card {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0px;
  }

  .rsvp-page-link {
    padding-left: unset;
    padding-right: unset;
    padding-bottom: 16px;
    margin-bottom: unset;
  }

  .rsvp-page-link-active {
    text-transform: uppercase;
    border-bottom: 2px solid #2774ae;
    border-left: unset;
    left: unset;
    padding-bottom: 16px;
  }
}

/***** EVENT CREATED MODAL STYLES *****/
@media only screen and (max-width: 560px) {
  .event-created-modal {
    position: fixed;
    max-width: unset;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    max-height: 100vh;
  }

  .event-created-modal img {
    height: 200px;
  }
}

.clone-event-warning {
  min-height: 75px;
  width: 70%;
  border-radius: 4px;
  background-color: rgba(39, 116, 174, 0.12);
  padding: 20px;
}

/***** RSVP EVENT MANAGEMENT STYLES *****/
.rsvp-manage-event-sidebar-menu-card ul {
  justify-content: flex-start;
}

.rsvp-manage-event-sidebar-menu-card > div {
  border-top: 1px solid #edecec;
  margin-top: 24px;
}

.event-location-toggle label {
  text-align: center;
}

@media only screen and (max-width: 1040px) {
  .rsvp-manage-event-sidebar-menu-card {
    align-content: center;
    justify-content: space-between;
  }

  .rsvp-manage-event-sidebar-menu-card > div {
    border-top: none;
    margin-top: 0px;
    margin-bottom: 16px;
    display: flex;
    align-content: center;
  }
}

@media only screen and (max-width: 560px) {
  .rsvp-manage-event-sidebar-menu-card > div {
    display: block;
    margin-bottom: 0px;
    margin-top: 4px;
    font-size: 12px;
  }

  .rsvp-manage-event-sidebar-menu-card > div > div {
    justify-content: flex-end;
  }
}

.program-management-select__single-value .canceled-event {
  color: #f3893d;
}

@media only screen and (max-width: 640px) {
  .event-location-toggle {
    font-size: 13px;
  }
}

/***** RSVP RESOURCE MANAGEMENT STYLES *****/
// "Active" switch styles
.MuiSwitch-switchBase.Mui-checked {
  color: #4fcec5 !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #4fcec5 !important;
}

// "Active" label styles
.MuiTypography-body1 {
  font-family: "AvenirBook" !important;
}

.program-management-select__single-value .inactive-resource {
  color: #f3893d;
}

/*** Student Actions Styles ***/
.rsvp-student-actions-dropdown-box {
  right: 0;
  z-index: 1;
  width: 144px;
}

.rsvp-student-actions-dropdown-box .btn.btn-accent {
  background: transparent;
  border: none;
  color: $accent-text-dark;
  font-size: 12px;
  text-transform: none;
  padding: 0;
  min-width: 0;
  margin-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

/*** Student Table Styles ***/
.rsvp-students-table {
  @extend .students-table;
  min-width: 900px;
}

.rsvp-students-table-row {
  @extend .students-table-row;
  grid-template-columns: 20px 1.3fr 2fr 1fr 1fr 1fr 1fr 20px !important;
}

.rsvp-students-table-row-extended {
  @extend .rsvp-students-table-row;
  grid-template-columns: 20px 1.3fr 2fr 1fr 1fr 1fr 1fr 1fr 20px !important;
}

@media only screen and (max-width: 560px) {
  @mixin mobile-rsvp-students-table-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    padding: 8px 16px;
    width: calc(100% + 32px);
  }

  .rsvp-students-table-row {
    @include mobile-rsvp-students-table-row();
  }

  .rsvp-students-table-row-extended {
    @include mobile-rsvp-students-table-row();
    width: calc(100vw - 16px);
  }
}

/*** Interaction Button Styles ***/
.event-interaction-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 16px;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}

.event-interaction-btn-tooltip {
  @extend .tooltip;
  left: unset;
  right: 0px;
  margin-bottom: 10px;
  width: 310px;
  white-space: pre-wrap;
  line-height: 1.5;

  @media only screen and (max-width: 560px) {
    right: 10px;
  }
}

.event-interaction-not-generated-reason {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 15px;
}

// Toastify styles
:root {
  --toastify-color-success: #4fcec5;
  --toastify-color-error: #ff6b6b;
  --toastify-text-color-success: #1e1c1c;
  --toastify-text-color-error: #1e1c1c;
}

.Toastify__close-button {
  align-self: center;
  margin-right: 4px;
}

.Toastify__close-button > svg {
  fill: #1e1c1c;
}

/*** Add Student Modal Styles ***/
@media only screen and (max-width: 560px) {
  .add-student-modal {
    position: fixed;
    max-width: unset;
    width: 100%;
    height: 100vh;
    max-height: unset;
    top: 0;
    overflow: auto;
    padding: 24px 16px;
  }

  .add-student-btns {
    flex-direction: column-reverse;
    padding: 24px 16px 16px;
    border-top: 1px solid #edecec;
    margin: 24px 0 -56px;
  }

  .add-student-btns .btn-accent {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-top: 0;
    margin-left: 0;
  }

  .add-student-btns .link-text.uppercase-text {
    background: transparent;
    text-decoration: underline;
    color: $accent-text;
    text-transform: none;
    margin-top: 0;
    border: none;
    margin-top: 8px;
  }

  .add-student-btns .error-message {
    bottom: 12px;
    width: 100%;
    text-align: center;
  }
}

.waive-fee-checkbox.custom-checkbox-container label {
  line-height: 1.5;
}

.waive-fee-checkbox.custom-checkbox-container label:before {
  margin-top: 4px;
}

.waive-fee-checkbox.custom-checkbox-container
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
  top: 5px;
}

/*** Settings Styles ***/
.recurring-event-day {
  height: 32px;
  width: 32px;
  border: 1px solid #edecec;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms linear;
  cursor: pointer;
}

.recurring-event-day.active {
  color: #fff;
  background: $accent-color;
}

.event-capacity-tables-grid {
  grid-gap: 40px;
}

.event-settings-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}

.event-reminder-sent-message {
  margin-left: 8px;
  max-width: 300px;
}

@media only screen and (max-width: 560px) {
  .rsvp-event-settings-form .program-form-section.cancel-schedule-section {
    padding: 16px 0px 0px;
    display: flex;
  }

  .cancel-schedule-section .MuiButton-root {
    max-width: 170px;
    padding: 8px;
  }

  .rsvp-event-settings-form .program-form-section {
    padding: 24px 16px 0;
  }

  .event-capacity-tables-grid {
    grid-gap: 16px;
  }

  .rsvp-event-settings-form .cancel-schedule-section .btn-light {
    background: transparent;
    text-decoration: underline;
    color: $accent-text;
    text-transform: none;
    margin-top: 0;
    border: none;
    padding: 0;
    height: 32px;
  }

  .event-reminder-sent-message {
    margin-left: 0px;
    margin-bottom: 24px;
    padding-top: 0px;
    max-width: unset;
  }
}

/*** Manage Student Settings Modal Styles ***/
.manage-student-settings-modal .student-settings-grid {
  @extend .three-column-grid;
}

.manage-student-settings-modal .radio-container {
  width: 100%;
}

.manage-student-settings-modal .radio-container label {
  width: 100%;
}

@media only screen and (min-width: 1042px) {
  .manage-student-settings-modal {
    .MuiDialog-paper {
      width: 840px;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .manage-student-settings-modal .student-settings-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
  }
}

@media only screen and (max-width: 560px) {
  .manage-student-settings-modal .student-settings-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .manage-student-settings-modal .radio-container {
    width: 100%;
  }

  .manage-student-settings-modal .radio-container label {
    width: 100%;
  }

  .manage-student-settings-modal .tooltip {
    left: -8px;
    right: auto;
  }
}

.rsvp-tag-container.error {
  border: 1px solid $error-color;
}
