//sidebar
.date-range {
  width: 100% !important;
  .react-daterange-picker__calendar {
    width: 100% !important;
  }
  .react-daterange-picker__wrapper {
    border: thin solid #d8d8dd;
    border-radius: 4px;
  }
  .react-daterange-picker__range-divider {
    flex-grow: 1;
  }

  .react-calendar {
    width: 100% !important;
  }
}

.sms-wizard-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 560px) {
    min-height: 62vh;
  }
}

.sms-btn {
  min-width: 96px;
  padding: 0 16px;
}

.sms-wizard-title {
  margin-top: 64px;
  margin-left: 32px;
  @media only screen and (max-width: 560px) {
    margin-top: 32px;
  }
}
.sms-wizard-close-btn {
  @media only screen and (min-width: 560px) {
    display: none;
  }
  margin: 10px;
  cursor: pointer;
}
.sms-wizard-container {
  .sms-wizard-filter-base {
    @media only screen and (min-width: 560px) {
      display: flex;
    }
    align-items: center;
  }
  .sms-wizard-filter {
    @extend .sms-wizard-filter-base;
    margin-top: 24px;
  }
  .checkbox-filter-div {
    @extend .sms-wizard-filter;
    margin-top: 8px;
    margin-bottom: 8px;
    label {
      min-width: 129px;
      margin-right: 77px;
    }
  }

  .select-recipient-label {
    width: 130px;
  }
  .sms-warning-icon {
    font-size: 16px;
    margin-left: 5px;
    color: #eed202;
    position: relative;
    top: 2px;
    @media only screen and (max-width: 560px) {
      font-size: 12px;
      top: 1px;
    }
  }
  .sms-review-mobile {
    display: block;
    .first-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .second-row {
      @extend .accent-text;
      margin-top: 5px;
    }

    .student-name {
      font-weight: 900;
    }
    .tag-name {
      text-align: end;
    }
    .program-participated {
      @extend .accent-text;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .remove-icon-container {
      text-align: end !important;
    }
    .remove-icon {
      @extend .link-text-secondary, .accent-text;
      font-size: 20px;
    }
    .dash-outer-div {
      display: inline-flex;
      width: 22px;
      justify-items: center;
      padding-right: 5px;
      padding-left: 5px;
    }
    .dash-inner-div {
      display: inline-block;
      width: 15px;
      height: 10px;
      justify-items: center;
    }
  }
  .sms-review-grid-cols {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .compose-message-calculate-header {
    @media only screen and (max-width: 560px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .select-recipient-label-div {
    @media only screen and (max-width: 560px) {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0px;
    }
    display: inline-block;
    width: 175px;
    margin-right: 30px;
  }
  .select-recipient-label-right-column {
    margin-left: 100px;
    @media only screen and (max-width: 560px) {
      margin-left: 0px;
    }
  }
  .select-recipient-input-div {
    @media only screen and (max-width: 560px) {
      margin-bottom: 10px;
    }
    display: inline-block;
  }

  .compose-message-section {
    @media only screen and (max-width: 560px) {
      height: 350px;
      .sms-text-area-label {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }
    @media only screen and (min-width: 560px) {
      display: inline-block;
      display: flex;
      .sms-text-area-label {
        width: 170px;
      }
    }
    border: 1px solid #edecec;
    border-radius: 3px;
    height: 200px;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 55px;
    textarea {
      resize: none;
      width: 100%;
      height: 100%;
      padding: 8px;
    }
    label {
      margin-right: 58px;
      margin-top: 15px;
      margin-bottom: 20px;
      width: 100px;
    }
    .calculate-btn {
      min-width: 120px;
      border-radius: 3px;
    }
  }
  .compose-message-row {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .upload-image-div {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .add-image-text {
    color: rgba(0, 0, 0, 0.48);
    height: 29px;
  }

  .compose-message-calculate {
    @media only screen and (min-width: 560px) {
      max-width: 350px;
    }
  }
  .compose-message-calculate-val {
    @media only screen and (min-width: 560px) {
      width: 50px;
    }
  }
}
.sms-compose-mes-modal {
  @extend .modal, .modal-card, .card, .relative;
  width: 500px;
  min-height: 300px;
  .buttons-section {
    margin-top: 140px;
    text-align: right;
  }
}
.message-status-content {
  text-align: center;
  max-width: 100%;
  margin: 20px;

  .message-container {
    display: flex;
    justify-content: center;
  }
  .message {
    @extend .fw-900, .xxl-text, .mt-24, .mb-24;
    max-width: 450px;
  }
  img {
    max-width: 100%;
  }
  @media only screen and (max-width: 560px) {
    padding-bottom: 100px;
    button {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.sms-status-page-link {
  color: #2774ae;
  font-weight: 900;
  cursor: pointer;
}

//add credit modal

.sms-button-primary {
  min-width: 96px !important;

  background-color: rgb(39, 116, 174) !important;

  &:hover {
    background-color: #ffffff !important;
    color: rgb(39, 116, 174);
    border: 1px solid rgb(39, 116, 174) !important;
    border-color: rgb(39, 116, 174);
  }
}
.sms-send-button {
  @extend .sms-button-primary;
  border-top: 1px solid rgb(39, 116, 174) !important;
  min-width: 10px !important;
}
.sms-button-secondary {
  min-width: 96px !important;
  background-color: #edecec !important;
  &:hover {
    background-color: #000 !important;
    color: #ffffff;
  }
}

.sms-personalize-button-area {
  position: absolute;
  bottom: 10px;
  left: 10px;
  margin-right: 14px;
  display: flex;
  @media only screen and (max-width: 560px) {
    display: block;
  }
}

.sms-add-credit-section {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 18px;
}

.wizard-review-row {
  border-bottom: none;
  padding-bottom: 0px;
  padding-top: 0px;
}
