/***** GENERAL RAFFLES PAGE STYLES *****/
.raffle-card {
  box-shadow: none;
}

.raffle-card .xxl-text {
  font-size: 22px;
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.raffles-page.page {
  background-color: #fff;
  padding: 0px 40px 40px 0px;
}
@media only screen and (max-width: 1040px) {
  .raffles-page.page {
    padding: 0 24px 32px;
  }
}

@media only screen and (max-width: 560px) {
  .raffles-page.page {
    min-height: calc(100vh - 104px);
    padding: 0 0 96px 0;
  }

  .raffles-page .page-title {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .raffle-card {
    border-radius: 0;
    border: none;
    padding: 0;
  }

  .raffle-card .xxl-text {
    font-size: 18px;
    margin: 0 -16px 32px;
    padding: 0 16px 16px;
  }
}

/***** ENROLLMENT FORM STYLES *****/
.custom-input.custom-message {
  height: 80px;
  width: calc(100% - 28px);
  resize: none;
  margin-left: 28px;
  padding: 8px;
}

.raffles-form-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 32px;
}

textarea.custom-input.custom-message {
  width: calc(100% - 28px);
  padding: 12px;
  margin-left: 28px;
  font-size: 14px;
}

textarea.custom-input.custom-message.full-width {
  width: 100%;
  margin-left: 0px;
}

.total-characters {
  position: absolute;
  bottom: 6px;
  right: 4px;
  margin-right: 14px;
  font-size: 11px;
  color: $accent-text;
}
.image-upload {
  flex-wrap: wrap;
}

.image-upload-container img {
  height: 60px;
  width: 60px;
  border-radius: 3px;
  object-fit: cover;
  margin-right: 32px;
}

.image-upload-loader svg#loading {
  width: 60px;
  height: 60px;
}

.iframe-container {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 12px;
  align-items: flex-start;
  width: 100%;
  min-height: 88px;
}

.chip-container {
  flex-wrap: wrap;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 0px 8px 8px 8px;
  width: 100%;
}

.chip {
  height: 32px;
  width: auto;
  padding: 0 16px;
  border-radius: 19.5px;
  background-color: rgba(237, 236, 236, 0.6);
  margin-right: 8px;
  margin-top: 8px;
}

.chip-container input,
.chip-container input:focus {
  height: 32px;
  border: none;
  outline: none;
}

.prize {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
  height: 48px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 24px;
  align-items: center;
  border-radius: 3px;
}

.prize-place {
  width: 120px;
}

.enrollment-form-btns {
  gap: 12px;
}

.enrollment-form-btns .btn {
  padding: 0 24px;
}
.email-signature-section {
  .label {
    padding-left: 28px !important;
    font-size: 14px;
  }
  .text-area {
    height: 180px;
  }
  .error {
    left: 28px;
  }
}
.raffles-section-title {
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  cursor: pointer;
}
.expanded-section {
  border-top: 1px solid #edecec;
  padding-top: 32px;
  padding-bottom: 12px;
  margin-top: 16px;
}
@media only screen and (max-width: 560px) {
  .raffles-form-section:not(:last-child) {
    margin: 0 -16px 32px;
    padding: 0 16px 24px;
  }

  .raffles-page .enrollment-card .medium-text.fw-700 {
    font-size: 14px;
  }

  .radio-button-container span.accent-text.ml-24 {
    margin-left: 0;
    display: block;
  }

  .prize {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    height: auto;
  }

  .mobile-prize-wrapper {
    display: block;
  }

  .mobile-prize-wrapper p {
    font-size: 12px;
  }

  .mobile-prize-wrapper p:nth-child(2).medium-text.fw-700 {
    margin: 8px 0;
    font-size: 16px;
  }

  .fees-info {
    font-size: 12px;
  }

  .enrollment-form-btns .btn {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }
}

/***** MY RAFFLE STYLES *****/
/*** My Raffle Sidebars Styles ***/
.raffles-sidebar {
  padding: 24px 16px;
  min-width: 325px;
}
.raffles-sidebar-card {
  width: 256px;
  padding: 24px;
}
.raffle-link {
  @extend .profile-link;
  cursor: pointer;
}

.active-raffle-link {
  @extend .active-profile-link;
}

.raffles-sidebar-card label {
  color: rgba(0, 0, 0, 0.48);
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}

.raffles-sidebar-card .program-contact-info {
  margin: 0 -24px 24px;
  padding: 0 24px 24px;
  border-bottom: 1px solid #edecec;
}

.raffles-sidebar-card .program-contact-info p {
  margin-bottom: 0;
}

.raffles-menu .MuiTabs-indicator {
  left: 0;
}
.raffles-menu .MuiTab-wrapper {
  align-items: flex-start;
}
.raffles-menu .MuiTabs-root {
  border-bottom: none;
}
.raffles-menu .MuiTabs-scrollButtons {
  width: unset;
}

@media only screen and (min-width: 600px) {
  .raffles-menu .MuiTabs-root:not(.MuiTabs-vertical) .MuiTab-root {
    min-width: unset;
    padding: 6px 20px;
  }
}

@media only screen and (max-width: 1040px) {
  .raffles-page > .flex > .mr-24 {
    margin-right: 0;
  }
  .raffles-sidebar-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .raffles-sidebar-actions .breadcrumbs-container {
    margin-top: 16px;
  }
  .raffles-sidebar-actions .custom-select {
    width: 50%;
  }
  .raffles-sidebar-card {
    width: 100%;
  }

  .raffles-sidebar-card:first-child {
    margin-top: 32px;
  }
  .raffles-sidebar-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .raffles-sidebar-card.raffles-menu {
    height: 56px;
    padding: 0 24px;
    align-items: flex-start;
  }
  .raffles-sidebar-card.raffles-info {
    box-shadow:
      0 2px 5px -2px rgba(0, 0, 0, 0.12),
      0 2px 18px 2px rgba(0, 0, 0, 0.08);
  }

  .raffle-link {
    text-transform: none;
    margin: 0;
    font-family: AvenirRoman;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
  }
  .active-raffle-link {
    padding-left: 0;
    border-left: 0;
    border-bottom: 2px solid $accent-color;
    color: $accent-color;
  }
  .raffles-sidebar-card .program-contact-info {
    font-size: 12px;
    margin: 0 -16px 0;
    padding: 0 16px 24px;
    border-bottom: none;
  }

  .program-total-info {
    font-size: 12px;
    display: flex;
  }

  .program-total-info p {
    display: flex;
    flex-direction: column;
  }

  .program-total-info p span:first-child {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 560px) {
  .raffles-sidebar {
    padding: 16px;
    min-width: 100%;
  }

  .raffles-sidebar-card {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    padding: 16px;
  }
  .raffles-sidebar-actions {
    display: block;
  }
  .raffles-sidebar-actions .breadcrumbs-container {
    margin-top: 0px;
  }
  .raffles-sidebar-actions .custom-select {
    width: 100%;
  }
  .raffles-sidebar-card:first-child {
    margin-top: 0;
  }

  .raffles-sidebar-card.raffles-menu {
    height: 56px;
    padding: 0 16px;
  }
}

/*** My Raffle Orders Styles ***/
.dropdown-container {
  position: relative;
}

.dropdown-container:hover .dropdown {
  opacity: 1;
  visibility: visible;
}

.dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms linear;
  position: absolute;
  left: 0;
  width: 176px;
  z-index: 1;
}

.btn-dropdown {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 18px 2px rgba(0, 0, 0, 0.08);
  padding: 16px;
}

.csv-export-dropdown .error-message {
  position: unset;
}

.shliach-orders-buttons button {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.no-border-select {
  border: none;
  background: transparent;
  font-size: 14px;
  font-family: AvenirRoman;
}

.no-border-select:focus {
  outline: none;
}

.raffles-details-donors-table {
  overflow: auto;
}
.raffle-details-table-header {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  padding: 16px;
  margin-bottom: 16px;
}

.raffle-details-table-row {
  background: #f5f5f5;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 3px;
  margin-bottom: 8px;
  transition: all 300ms linear;
}
.raffle-details-table {
  max-width: 1300px;
}

.raffle-details-table + .pagination {
  display: flex;
  justify-content: flex-end;
}

.raffle-details-mobile-table-header {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  padding: 16px;
  margin-bottom: 16px;
}

.raffle-details-order-table-row {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 16px;
  line-height: 1.5;
}

.contents-mobile-grid {
  display: contents;
}

.order-details {
  display: contents;
}

.refunded {
  color: #e94746;
  text-decoration: line-through;
}

/* Order Details Modal Styles */
.order-details-modal .small-text.accent-text {
  width: 104px;
  margin-right: 24px;
}

.order-details-modal {
  border-radius: 3px;
  width: 600px;
  max-width: 96%;
}

.raffle-table-row-icon {
  color: rgba(0, 0, 0, 0.48);
}

@media only screen and (max-width: 560px) {
  .shliach-orders-buttons.ml-32 {
    margin-left: 0;
  }

  .raffle-details-order-table-filters {
    display: block;
  }
  .raffle-details-order-table-filters .filter-width {
    width: 100%;
    max-width: 280px;
    > p:first-child {
      min-width: 52px;
    }
  }
  .raffle-details-order-table-filters > div:last-child {
    justify-content: unset;
    > p:first-child {
      min-width: 52px;
    }
  }

  .raffle-details-order-table-row {
    display: block;
    position: relative;
    padding-left: 40px;
    margin: 0 -16px 8px;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
  }

  .raffle-details-table-header {
    display: grid;
    grid-template-columns: 1fr 110px;
    font-family: "AvenirHeavy";
  }

  .raffle-details-order-table-row
    > .desktop-hidden.tablet-hidden.collapse-icon {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  .contents-mobile-grid {
    display: grid;
    grid-template-columns: 1fr 110px;
  }

  .contents-mobile-grid p:not(.accent-text) {
    font-family: "AvenirHeavy";
  }

  .raffle-details-order-table-row > .flex {
    margin-bottom: 4px;
  }

  .orders-mobile-label {
    width: 120px;
  }

  .raffle-details-order-table-row.open .order-details {
    display: block;
    margin-top: 16px;
  }

  .raffle-details-order-table-row.closed .order-details {
    display: none;
  }
}

/* Pagination Styles */

.pagination ul > *:not(.disabled):not(.active) {
  transition: opacity 300ms linear;
  cursor: pointer;
}

.pagination ul > *:not(.disabled):not(.active):hover {
  opacity: 0.5;
}

.raffles-page .pagination li:not(:last-child) {
  margin-right: 16px;
}

.pagination ul li.active {
  height: 24px;
  width: 24px;
  background: $accent-color;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*** Bulk Import Modal Styles ***/
.import-modal {
  max-width: 544px;
  width: 95%;
  padding: 24px 24px 16px 24px;
}

.uploading {
  flex: 1;
  margin-left: 80px;
}

.raffle-modal-btns {
  border-top: 1px solid #edecec;
  padding: 16px 24px 0;
  margin: 0 -24px;

  :disabled {
    opacity: 0.5;
  }
}

.raffle-modal-btns .btn {
  font-size: 12px;
  padding: 0 16px;
  min-width: 96px;
  text-align: center;
}

@media only screen and (max-width: 560px) {
  .modal-card.card {
    height: auto;
  }

  .raffle-modal-btns .btn {
    width: 50%;
  }
}

/*** New Order Modal Styles ***/
.modal-container.order-modal-container {
  z-index: 1 !important;
}
.modal.new-order-modal {
  width: 640px;
  max-width: 95%;
  padding: 24px 24px 32px 24px;
  max-height: 84vh;
  height: 100%;
}

.modal-height {
  height: auto !important;
}

.new-order-modal-overflow {
  height: calc(100% - 48px);
  overflow: auto;
  padding: 24px 24px 0 0;
  margin: -24px -24px 0 0;
}

.new-order-modal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 24px;
}

.new-order-modal-grid label {
  width: 96px;
  min-width: 96px;
}

.new-order-modal-grid input,
.new-order-modal-grid .phone-input {
  width: 100%;
  padding-left: 10px;
}

.new-order-modal-grid .phone-input .MuiInputBase-input {
  padding-left: 2px;
  padding-top: 6px;
  font-size: 13px;
}

.new-order-modal-grid .span-two {
  grid-column-start: span 2;
}

.new-order-address {
  max-width: 512px;
}

.required:after {
  content: " *";
  color: red;
}

.new-order-address label,
.new-order-payment-method label {
  width: 176px;
  min-width: 176px;
}

.InputContainer.InputElement {
  font-size: 13px;
}

.new-order-checkbox .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}

.new-order-modal .raffle-modal-btns .error-message {
  font-size: 11px;
  right: 24px;
}

.new-order-error-message {
  top: 76px;
}

.edit-order-error-message {
  top: 56px;
}

.field-error-message {
  top: 32px;
  left: 96px;
}

.raffle-order-summary {
  max-width: 200px;
  font-size: 14px;
}

@media only screen and (max-width: 560px) {
  .modal-height {
    height: 100% !important;
    max-height: 100% !important;
  }

  .modal-mobile-overflow {
    height: calc(100% - 48px);
    overflow: auto;
    padding: 24px 24px 0 0;
    margin: -24px -24px 0 0;
  }

  .new-order-modal-grid {
    grid-template-columns: 1fr;
  }

  .new-order-modal-grid .span-two {
    grid-column-start: span 1;
  }

  .new-order-modal-grid-reverse-order {
    display: grid;
    grid-gap: 12px 0;
  }

  .new-order-modal-grid-reverse-order > div:first-child {
    grid-row: 2;
  }

  .new-order-modal .custom-input {
    width: 100%;
    margin-top: 4px;
  }

  .new-order-modal .flex.mr-24 {
    margin-right: 0;
  }

  .new-order-modal *[name="address.state"].mr-16 {
    margin-right: 0;
  }

  .new-order-modal-overflow {
    height: calc(100% - 80px);
  }

  .new-order-modal .raffle-modal-btns {
    flex-direction: column;
  }

  .new-order-modal .raffle-modal-btns div {
    width: 100%;
    margin-bottom: 8px;
  }

  .new-order-modal .raffle-modal-btns .error-message {
    bottom: -8px;
    top: auto;
    max-width: calc(100% - 80px);
    text-align: right;
  }

  .field-error-message {
    top: 52px;
    left: 0px;
  }

  .raffle-order-summary {
    max-width: 100%;
    font-size: 14px;
  }

  .modal-error {
    width: 92%;
    text-align: right;
    margin-top: 54px;
  }
}

/*** Settings Styles ***/
.raffle-settings-btns .btn {
  text-align: center;
  width: 104px;
  height: 40px;
  line-height: 40px;
}

.campaign-image {
  position: relative;
  display: inline-block;
}
.campaign-image .profile-pic-container img {
  border-radius: 3px;
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 0px;
}
.campaign-image .profile-pic-container .star {
  position: absolute;
  left: 4px;
  top: 4px;
  > path:first-of-type {
    opacity: 1;
    fill: #fff;
  }
  > path:last-of-type {
    opacity: 1;
    fill: #747579;
  }
  &.filled {
    > path:first-of-type {
      fill: #ffc602;
    }
    > path:last-of-type {
      fill: #fff;
    }
  }
}
.close {
  font-size: 14px;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #666666;
  color: #fff;
  padding: 2px;
  border-radius: 40px;
  overflow: visible;
}

.copy-code-container textarea {
  height: auto;
  border: none;
  resize: none;
  width: 100%;
  user-select: all;
  position: relative;
  overflow: hidden;
  font-family: AvenirRoman, sans-serif;
  font-size: 12px;
}

.copy-code-container textarea.no-selection-hover::selection {
  background: transparent;
}

.copy-code-container textarea:focus {
  outline: none;
  border: none;
}

.copy-code-container .fading-out:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 75%
  );
}

.raffles-sticky-header {
  position: sticky;
  background-color: #fff;
  padding: 16px 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.raffle-goal-input:after {
  content: "Tickets";
  position: absolute;
  right: 24px;
  font-size: 12px;
  top: 8px;
  color: $accent-color-dark;
}

.raffle-goal-wrapper {
  align-items: flex-end;
}

.raffle-goal-input.donor-goal-input:after {
  content: "Donors";
}

.raffle-ticket-options-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 1fr);
  max-width: 700px;
  margin-bottom: 24px;
}

.raffle-ticket-option-input {
  width: 100px;

  .MuiFormHelperText-contained {
    margin-left: 0px;
    margin-right: 0px;
    position: absolute;
    top: 36px;
  }
}

.raffle-ticket-option-select {
  width: 200px;
}

.raffle-donor-panel-settings .radio-container input + label {
  width: 148px;
}

@media only screen and (max-width: 560px) {
  .copy-code-container textarea {
    font-size: 11px !important;
    overflow: hidden;
  }

  .raffle-goal-input-container .custom-input,
  .raffle-goal-input-container .radio-container {
    width: 100%;
  }

  .raffle-goal-input-container .custom-input {
    margin-bottom: 16px;
  }

  .raffle-goal-input-container .radio-container label {
    width: 50% !important;
  }

  .raffle-settings-btns .btn {
    width: 80px;
  }

  .raffle-ticket-options-grid {
    display: block;
  }
}
@media only screen and (max-width: 1040px) {
  .raffles-sticky-header {
    display: block;
    padding: 8px 16px;
    margin: 0px -16px;
    > div {
      margin-top: 8px;
      justify-content: space-between;
    }
  }
}

/*** Settings - teams styles ***/
.modal-container.team-modal-container {
  z-index: 300 !important;
}
.teams-separator {
  border-top: 1px solid #edecec;
  padding-top: 18px;
  margin-top: 16px;
}

.teams-grid-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin-bottom: 8px;
  border-radius: 3px;
  min-height: 40px;
  align-items: center;
  width: calc(100% - 72px);
}

.team-section {
  height: 86px;
  width: 280px;
  padding: 18px 16px;
  border: 1px solid #2773ae8a;
  border-radius: 3px;
}

.team-modal.card {
  width: 600px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}

.team-modal label {
  font-weight: normal;
  font-size: 13px;
  min-width: 120px;
}

.team-modal .custom-input {
  width: 100%;
}

.team-modal .goal-input {
  width: 135px;
}

.team-modal .goal-error {
  bottom: 100px;
}

.team-modal .phone-input {
  padding-left: 10px;
}

.team-modal .phone-input .MuiInputBase-input {
  padding-left: 2px;
  padding-top: 6px;
  font-size: 13px;
}
.additional-text {
  margin-left: 120px;
}

.team-modal .btn {
  padding: 0 12px;
}

.student-dropdown {
  width: 100%;
}

.tickets-text {
  margin-left: 12px;
}

.team-modal-separator {
  border: 0.5px solid #edecec;
  margin: 28px -24px 22px;
}

.smaller-margin {
  margin: 16px -24px 22px;
}

.tooltip-height {
  height: 16px;
}

.cancel-btn {
  color: black;
}

.save-btn {
  color: white;
  background-color: $accent-color;
  min-width: 80px;
}
.raffle-enrollment-completed-card {
  height: 300px;
  max-height: 300px;
}

@media only screen and (max-width: 1040px) {
  .raffle-mobile-grid {
    grid-template-columns: 1fr;
    min-height: 300px;
    grid-gap: 8px;
  }
  .raffle-enrollment-completed-card {
    height: fit-content;
    max-height: fit-content;
  }
}

@media only screen and (max-width: 1040px) {
  .teams-grid-row {
    grid-template-columns: 1.5fr 2fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 560px) {
  .teams-grid-row {
    grid-template-columns: 1fr;
    height: auto;
    grid-gap: 8px;
  }

  .team-modal .custom-input {
    width: 100% !important;
  }

  .team-modal .goal-input {
    width: 135px !important;
  }

  .team-modal .modal-btns {
    margin-right: 72px;
  }

  .team-modal-separator {
    margin-bottom: 0px;
  }

  .additional-text {
    margin-left: 0px;
  }
}

/*** Metrics Styles ***/
.raffle-shliach-card .percentage-bar {
  width: 100%;
}

.money-status-card {
  margin-right: 24px;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 16px;
  width: 100%;
  transition: opacity 300ms linear;
  .MuiTypography-caption {
    font-family: AvenirRoman;
    letter-spacing: normal;
    line-height: normal;
  }
  .tooltip-icon {
    width: 14px;
    height: 14px;
    color: #747579;
  }
}

.money-status-card:not(.no-click):hover {
  opacity: 0.5;
  cursor: pointer;
}

.money-status-card:last-child {
  margin-right: 0;
}

.ticket-status-cards {
  display: flex;
  flex-direction: column;
}

.ticket-status-card {
  width: 204px;
  height: 200px;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  .tooltip-icon {
    width: 14px;
    height: 14px;
    color: #747579;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.ticket-status-card:last-child {
  margin-bottom: 0;
}

.icon-wrapper {
  height: 42px;
  width: 42px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.person-icon {
  background-color: rgba(99, 198, 116, 0.08);
}

.ticket-icon {
  background-color: rgba(243, 137, 61, 0.08);
}

.graph-outline {
  border: 1px solid #edecec;
  border-radius: 3px;
  width: 100%;
  padding: 24px 24px 0 24px;
}

.chart-no-results {
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chart-no-results-small {
  height: calc(100% - 80px);
}

.recharts-responsive-container {
  margin: 0 auto;
}

.recharts-cartesian-axis-ticks {
  font-size: 13px;
}

.recharts-surface {
  overflow: visible;
}

.recharts-legend-item {
  min-width: 140px;
  text-align: left;
  margin-bottom: 4px;
}

.raffle-metrics-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}

@media only screen and (max-width: 560px) {
  .raffle-shliach-card .medium-text.fw-700 {
    font-size: 12px;
  }

  .money-status-cards {
    flex-direction: column;
  }

  .money-status-card {
    padding: 12px 16px;
    margin-bottom: 8px;
  }

  .ticket-status-cards {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    position: relative;
    top: -16px;
  }

  .ticket-status-card {
    margin-bottom: 0;
    padding: 16px;
    width: 100%;
    height: unset;
    min-height: 154px;
  }

  .ticket-status-card .medium-text.fw-700 {
    font-size: 14px;
  }

  .graph-outline.ml-24 {
    margin-left: 0;
  }
}

/*** Raffle Donor Styles ***/
.donors-table-section-header {
  background: #f5f5f5;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.raffle-details-donors-table-row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30px;
  grid-auto-columns: 260px;
  grid-gap: 16px;
  line-height: 1.5;
  transition: opacity 300ms linear;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  width: fit-content;

  p:empty:before {
    content: " ";
    white-space: pre;
  }
}
.donors-table-summary-row {
  padding-bottom: 20px;
}
.donors-table-section.closed {
  display: none;
}
.donors-table-section.open {
  margin-top: 10px;
}
.donors-year-filter {
  width: 245px;
}

@media only screen and (max-width: 560px) {
  .raffle-details-donors-table-row {
    position: relative;
    margin: 0 -16px 8px;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    grid-auto-columns: 150px;
    width: auto;
    padding: 5%;
  }

  .raffle-details-mobile-table-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    border-bottom: 1px solid #e0e0e0;
    padding: 5%;
    width: auto;
    padding-left: 15%;
    font-size: 14px;
  }

  .raffle-details-table-header.raffle-details-donors-table-row {
    display: grid;
    font-family: "AvenirHeavy";
  }

  .raffle-details-donors-table-row
    > .desktop-hidden.tablet-hidden.collapse-icon {
    position: absolute;
    top: 8px;
    left: 28px;
  }

  .raffle-details-donors-table-row.open .order-details {
    display: block;
    margin-top: 16px;
  }

  .raffle-details-donors-table-row.closed .order-details {
    display: none;
  }
  .raffles-details-donors-table {
    max-width: 560px;
    overflow: hidden;
  }
}
